<template>
  <nav class="navbar navbar-expand-sm">
    <div class="mr-auto logo_espaco div_logo_celular d-block d-md-none">
      <a class="imagem navbar-brand header-link-1" href="#">
        <img src="@/assets/img/logo.png" alt="logo" class="img" />
      </a>
    </div>
    <button
      style="color: #fff"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="currentColor"
        class="bi bi-justify"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    </button>

    <div class="collapse navbar-collapse nav_top" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="/#home">Início</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/#nosso_servicos">Nossos Serviços</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/#portfolio">Portfólio</a>
        </li>
      </ul>
      <div class="mr-auto logo_espaco d-none d-md-block">
        <a class="imagem logo navbar-brand header-link-1" href="#">
          <img src="@/assets/img/logo.png" alt="logo" class="img" />
        </a>
      </div>
      <ul class="navbar-nav my-4 my-lg-0 class_btn_topo">
        <li>
          <a href="#contato">
            <button type="button" class="btn_topo">
              <span>Vamos Conversar?</span>
            </button>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template> 
<script>
</script>
<style>
@import "../../app.scss";
</style>
<style scoped>
body * {
  font-family: "Montserrat";
}
nav {
  background-color: #000;
}
.navbar {
  position: fixed;
  width: 100%;
  z-index: 500;
}
.nav-link {
  color: #ffffff;
  transition: 0.5s;
  font-family: "PoppinsMedium";
}
.nav-link:hover {
  color: #01bd9e;
}
/*---btn---*/
.btn_topo {
  padding: 10px;
  margin-right: 100px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  text-align: center;
  border: none;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(93.31deg, #01bd9e 0.68%, #002c39 109.79%);
  border-radius: 40px;
}
.btn_topo:hover {
  background: linear-gradient(93.31deg, #002c39 0.68%, #01bd9e 109.79%);
}
#navbarSupportedContent {
  margin-left: 100px;
}
span {
  color: #fff;
  font-family: "PoppinsMedium";
}
.img {
  width: 100%;
  height: 50px;
}
.logo_espaco {
  margin-left: -100px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .logo_espaco {
    margin-left: 0;
  }
  .img {
    height: 40px;
  }
  .logo {
    margin-left: -60px;
  }
  .logo img {
    width: 200px;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn_topo {
    margin-right: 0px;
    padding: 5px;
  }
  #navbarSupportedContent {
    margin-left: 0px;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0px !important;
  }

  .navbar-brand {
    margin-left: 100px;
  }
  .logo {
    margin-left: -10px;
  }
  .logo img {
    width: 180px;
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .logo_espaco {
    margin-left: 0px;
  }
  .div_logo_celular > a > img {
    width: 200px;
    height: auto;
  }
  .class_btn_topo {
    display: none;
  }
  #navbarSupportedContent {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .logo_espaco {
    margin-left: 0;
  }
  #navbarSupportedContent {
    text-align: center;
    margin-left: 0;
    margin-top: 15px;
  }
  .class_btn_topo {
    display: none;
  }
}
</style>