var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9iYWNrZW5kYWdyby5jZW50cmlvbi5jb20uYnJcL2FwaVwvbG9naW4iLCJpYXQiOjE2NTQ2OTgyMDMsImV4cCI6MTY1NDcwMTgwMywibmJmIjoxNjU0Njk4MjAzLCJqdGkiOiJ3MzhPRTNhVWFlQmI2SXRhIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.gVCti-_bkN9c8k8Lo1WQQEcqc0gx6MfMfc8frJchgkg';

export default httpClient => ({

  getAll: async () => {

    const response = await httpClient.get('/produtos',{
      headers: {
        'X-Header-Token': token
      }
    })
    
    return { data: response.data }

  },

  getRelacionados: async ({categoria_id}) => {
    const response = await httpClient.get('/produtos-relacionados/'+categoria_id,{ 
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  },

  getDestaque: async () => {
    const response = await httpClient.get('/produtos-destaque',{ 
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  },

  getMaisAcessados: async () => {
    const response = await httpClient.get('/produtos-mais-acessados',{ 
      headers: {
        'X-Header-Token': token
      } 
    })
    return { data: response.data }
  },

  getPorCategoria: async (categoria_id) => {
    const response = await httpClient.get('/produtos-por-categoria/'+categoria_id,{ 
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  },

  getById: async ({ id }) => {
    const response = await httpClient.get('/produto/'+id,{ 
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  },
            
  save: async ({ codigo, nome, nome_url, descricao, preco, preco_promocional, status, destaque, categoria_id }) => {
    const response = await httpClient.post('/produto/adicionar',{
      codigo,
      nome,
      nome_url,
      descricao,
      preco,
      preco_promocional,
      status,
      destaque,
      categoria_id
    },{
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  update: async ({ id, codigo, nome, nome_url, descricao, preco, preco_promocional, status, destaque, categoria_id }) => {
    const response = await httpClient.post('/produto/alterar/'+id,{
      codigo,
      nome,
      nome_url,
      descricao,
      preco,
      preco_promocional,
      status,
      destaque,
      categoria_id
    }, {
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  save_imagens: async (formData) => {
      
      let headers = {
        'Content-Type': 'multipart/form-data'
      };
      headers['X-Header-Token'] =token
      const response = await httpClient.post('/produto/adicionar-imagens',formData,{
        headers: headers})
      console.log("Resposta direta: "+response.data)
      return {
        data: response.data
      }
  },

    getImagens: async({ produto_id }) => {
      const laudos = await httpClient.post('/produto/imagens',{
        produto_id: produto_id
      }, {
        headers: {
          'X-Header-Token': token
        }
      })
      var array_imagens_documentos = [];
      for (var i = 1; i <= laudos.data.length; i++) {


        var imagem_documento = await httpClient.post('/produto/imagem',{
          id: laudos.data[i - 1].id
        },{
        headers: {
          'X-Header-Token': token
        }
        })
        array_imagens_documentos.push(imagem_documento.data)

      }
      return array_imagens_documentos;
    },
  delete: async ({ id }) => {
    const response = await httpClient.delete('/produto/deletar/' + id, {
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  }
})