const defaultPagination = {
  limit:5,
  offset: 0
}

var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9iYWNrZW5kYWdyby5jZW50cmlvbi5jb20uYnJcL2FwaVwvbG9naW4iLCJpYXQiOjE2NTQ2OTgyMDMsImV4cCI6MTY1NDcwMTgwMywibmJmIjoxNjU0Njk4MjAzLCJqdGkiOiJ3MzhPRTNhVWFlQmI2SXRhIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.gVCti-_bkN9c8k8Lo1WQQEcqc0gx6MfMfc8frJchgkg';

export default httpClient => ({
  getAll: async ({type, limit, offset } = defaultPagination) => {
    const query = { limit, offset}
    if(type) {
      query.type = type
    }
    const response = await httpClient.get('/categorias',{
      headers: {
        'X-Header-Token': token
      }
      })
    
    return { data: response.data }

  },
  getById: async ({ id }) => {
    const response = await httpClient.get('/categoria/'+id,{ 
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  },
            
  save: async ({ nome, status }) => {
    const response = await httpClient.post('/categoria/adicionar',{
      nome,
      status
    }, {
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ id, nome, descricao, status }) => {
    const response = await httpClient.post('/categoria/alterar/'+id,{
      nome : nome,
      descricao: descricao,
      status: status
      
    }, {
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    delete: async ({ id }) => {
    const response = await httpClient.delete('/categoria/deletar/' + id, { 
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  }
})