/*!

=========================================================
* Vue Argon Dashboard - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import { Collapse } from 'vue-collapsed'
import 'animate.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
const appInstance = createApp(App);


appInstance.component('Collapse', Collapse);

appInstance.use(AOS.init());
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
