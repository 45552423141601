const defaultPagination = {
  limit: 5,
  offset: 0
}

var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9iYWNrZW5kYWdyby5jZW50cmlvbi5jb20uYnJcL2FwaVwvbG9naW4iLCJpYXQiOjE2NTQ2OTgyMDMsImV4cCI6MTY1NDcwMTgwMywibmJmIjoxNjU0Njk4MjAzLCJqdGkiOiJ3MzhPRTNhVWFlQmI2SXRhIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.gVCti-_bkN9c8k8Lo1WQQEcqc0gx6MfMfc8frJchgkg';

export default httpClient => ({
  getAll: async ({ type, limit, offset } = defaultPagination) => {
    const query = { limit, offset }
    if (type) {
      query.type = type
    }
    const response = await httpClient.get('/homes', {
      headers: {
        'X-Header-Token': token
      }
    })

    return { data: response.data }

  },
  getById: async ({ id }) => {
    const response = await httpClient.get('/home/' + id, {
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  },

  save: async ({ telefone, whatsapp, email, rua, bairro, estado, cidade, cep, }) => {
    const response = await httpClient.post('/home/adicionar', {
      telefone,
      whatsapp,
      email,
      rua,
      bairro,
      estado,
      cidade,
      cep,
    }, {
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ id, telefone, whatsapp, email, rua, bairro, estado, cidade, cep }) => {
    const response = await httpClient.post('/home/alterar/' + id, {
      id,
      telefone,
      whatsapp,
      email,
      rua,
      bairro,
      estado,
      cidade,
      cep

    }, {
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if (!response.data) {
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  delete: async ({ id }) => {
    const response = await httpClient.delete('/home/deletar/' + id, {
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  }
})