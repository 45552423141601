import axios from 'axios'
import AuthService from './auth'
import CategoriaService from './categoria'
import HomeService from './home'
import ProdutoService from './produto'
import FaleConoscoService from './faleConosco'
import TipoChamadoService from './tipoChamado'
import UsuarioService from './usuario'
import NewsletterService from './newsletter'
import BannerService from './banner'
import ContatoService from './contato'

const API_ENVS = {
    production: '',
    development: '',
    local: 'http://localhost:8990/api/', 
}



const httpClient = axios.create({
    baseURL: API_ENVS.local
})


export default {
    devServer: {
        proxy: 'https://localhost::8990/api/'
    },
    auth: AuthService(httpClient),
    categoria: CategoriaService(httpClient),
    banner:BannerService(httpClient),
    home: HomeService(httpClient),
    produto: ProdutoService(httpClient),
    FaleConosco: FaleConoscoService(httpClient),
    tipoChamado: TipoChamadoService(httpClient),
    usuario: UsuarioService(httpClient),
    newsletter: NewsletterService(httpClient),
    contato: ContatoService(httpClient),
}