export default httpClient => ({
    getAll: async () => {
      const response = await httpClient.get('/doencas')
      return { data: response.data }
    },
    save: async ({ nome, descricao }) => {
      const response = await httpClient.post('/doenca/adicionar',{
        nome,
        descricao
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    update: async ({ id, nome,descricao }) => {
      const response = await httpClient.post('/doenca/alterar/'+id,{
        nome : nome,
        descricao : descricao
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
})