<template>
  <main>
    <topo />
    <section>
        <div class="container">
          <div class="row">
            <div class="col-sm-8">
            </div>
            <div class="col-sm-4 div_texto">
              <h1>Quem Somos</h1>
            </div>
          </div>
        </div>
    </section>
    <bottom />
  </main>
</template>

<script>
import Topo from '../../layout/Site/Topo.vue'
import Bottom from '../../layout/Site/Bottom.vue'
export default({
    setup() {
        return {
          Topo,
          Bottom
        }     
    }

})
</script>

<style scoped>
  .img_sobre{
    width: 100%;
  }
  .div_texto{
    padding-top: 8 0px;
  }
</style>