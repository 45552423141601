<template>
  <footer>
    <div class="row">
      <div class="col-md-6">
        <img src="@/assets/img/logo_footer.png" alt="logo" class="img" />
      </div>
      <div class="col-md-6">
        <p class="footer_descricao">
          Todos os Direitos Reservados © 2020 - 2024
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { onMounted, reactive } from "vue";
import services from "../../services";

export default {
  setup() {
    onMounted(() => {
      fetchHome();
    });
    const state = reactive({
      email_newsletter: "",
      telefone: "",
      whatsapp: "",
      email: "",
      rua: "",
      bairro: "",
      estado: "",
      cidade: "",
      cep: "",
    });

    async function fetchHome() {
      try {
        state.isLoading = true;
        const { data } = await services.home.getById({
          id: 1,
        });
        console.log(data);
        state.telefone = data.telefone;
        state.whatsapp = data.whatsapp;
        state.email = data.email;
        state.rua = data.rua;
        state.bairro = data.bairro;
        state.cidade = data.cidade;
        state.estado = data.estado;
        state.cep = data.cep;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }
    async function saveNewsletter() {
      const { error } = await services.newsletter.save({
        email: state.email_newsletter,
      });
      if (!error) {
        state.isLoading = false;
      }
      return {};
    }
    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    return {
      saveNewsletter,
      state,
    };
  },
};
</script>
<style>
@import "../../app.scss";
</style>
<style scoped>
body * {
  font-family: "Montserrat";
}
footer {
  padding: 20px;
}
.img {
  width: 150px;
  height: 25px;
  margin-left: 30px;
  margin-top: 10px;
}
.footer_descricao {
  color: #000;
  text-align: right;
  margin-right: 30px;
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .footer_descricao {
    text-align: right;
    margin-right: 0px;
    margin-top: -25px;
  }
  .img {
    margin-top: 30px;
    margin-left: 0px;
  }
}
@media (max-width: 575px) {
  .footer_descricao {
    font-size: 10pt;
    text-align: left;
    margin-top: 0px;
  }
  .img {
    margin-left: 0px;
  }
}
</style>
