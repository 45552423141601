export default httpClient => ({
    contato: async ({ token, nome, email, telefone, assunto, mensagem }) => {
        const response = await httpClient.post('/enviar-email', {
            token,
            nome,
            email,
            telefone,
            assunto,
            mensagem
        }, {
            headers: {
                'X-Header-Token': token
            }
        })
        return {
            data: response.data,
        }
    }
})