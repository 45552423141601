<template>
  <topo />
  <section>
    <div class="container" style="padding-top: 100px">
      <h1>Política de Privacidade</h1>

      <p>Última atualização: 07/09/2024</p>

      <h2>01. Introdução Bem-vindo!</h2>

      <p>
        Esta Política de Privacidade explica como coletamos, usamos, divulgamos
        e protegemos suas informações quando você usa nosso aplicativo. Ao usar
        nosso aplicativo, você concorda com a coleta e o uso de informações de
        acordo com esta política.
      </p>
      <h2>2. Informações que Coletamos</h2>
      <h2>2.1. Informações Pessoais</h2>
      <p>
        Informações Pessoais Podemos coletar informações pessoais
        identificáveis, como nome, endereço de e-mail e número de telefone,
        quando você se registra ou utiliza nosso aplicativo.
      </p>
      <h2>2.2. Informações Não Pessoais</h2>
      <p>
        Podemos coletar informações não pessoais, como dados de uso, endereço IP
        e informações sobre o dispositivo que você está usando. Essas
        informações ajudam a melhorar a experiência do usuário e a analisar o
        uso do aplicativo.
      </p>
      <h2>2.3. Informações de Localização</h2>
      <p>
        Se o aplicativo usar recursos de localização, podemos coletar e
        processar informações sobre sua localização precisa para fornecer
        funcionalidades baseadas em localização.
      </p>
      <h2>3. Como Usamos Suas Informações</h2>

      <h2>3.1. Para Fornecer e Melhorar o Serviço</h2>

      <p>
        Usamos suas informações para operar e melhorar nosso aplicativo,
        personalizar sua experiência e responder às suas solicitações e
        perguntas.
      </p>
      <h2>3.2. Para Comunicação</h2>
      <p>
        Podemos usar suas informações para enviar notificações, atualizações e
        outros comunicados relacionados ao aplicativo. Você pode optar por não
        receber essas comunicações a qualquer momento.
      </p>
      <h2>3.3. Para Análise e Pesquisa</h2>
      <p>
        Podemos usar dados agregados e anônimos para realizar análises e
        pesquisas a fim de entender melhor como nosso aplicativo é usado e como
        podemos melhorá-lo.
      </p>
      <h2>4. Compartilhamento de Informações</h2>
      <h2>4.1. Com Prestadores de Serviços</h2>
      <p>
        Podemos compartilhar suas informações com prestadores de serviços
        terceirizados que nos ajudam a operar o aplicativo e fornecer serviços.
        Esses prestadores de serviços são obrigados a manter a confidencialidade
        das suas informações.
      </p>
      <h2>4.2. Por Exigência Legal</h2>
      <p>
        Podemos divulgar suas informações se exigido por lei ou em resposta a
        solicitações legais, como ordens judiciais ou intimações.
      </p>
      <h2>4.3. Em Caso de Mudança de Propriedade</h2>
      <p>
        Se o nosso aplicativo for adquirido ou fundido com outra empresa, suas
        informações podem ser transferidas para a nova entidade.
      </p>
      <h2>5. Segurança das Informações</h2>
      <p>
        Adotamos medidas razoáveis para proteger suas informações contra acesso
        não autorizado, alteração, divulgação ou destruição. No entanto, nenhum
        método de transmissão de dados pela internet ou método de armazenamento
        eletrônico é 100% seguro, e não podemos garantir segurança absoluta.
      </p>
      <h2>6. Seus Direitos</h2>
      <p>
        Você tem o direito de acessar, corrigir ou excluir suas informações
        pessoais que possuímos. Se desejar exercer esses direitos, entre em
        contato conosco através das informações fornecidas abaixo.
      </p>
      <h2>7. Alterações a Esta Política de Privacidade</h2>
      <p>
        Podemos atualizar esta Política de Privacidade periodicamente.
        Informaremos sobre quaisquer alterações publicando a nova política no
        aplicativo. Recomendamos que você reveja esta política regularmente para
        estar ciente de qualquer mudança.
      </p>
      <h2>8. Contato</h2>
      <p>
        Se você tiver alguma dúvida ou preocupação sobre esta Política de
        Privacidade ou sobre nossas práticas de privacidade, entre em contato
        conosco: Nome da Empresa: Centrion Web E-mail: centrionweb@gmail.com
    </p>
    </div>
  </section>
  <bottom />
</template>
<script>
import Topo from "../../layout/Site/Topo.vue";
import Bottom from "../../layout/Site/Bottom.vue";
import { onMounted, reactive } from "vue";
import services from "../../services";

export default {
  setup() {
    onMounted(() => {
      if (window.$wow) {
        window.$wow.init();
        window.$wow.sync();
      }
      fetchBanners();
      fetchDestaques();
    });

    const state = reactive({
      nome: "",
      email: "",
      telefone: "",
      titulo: "",
      mensagem: "",
      destaques: [],
    });

    async function fetchBanners() {
      try {
        state.isLoading = true;
        const { data } = await services.banner.getAtivos({
          type: state.currentClientType,
        });
        state.banners = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchDestaques() {
      try {
        state.isLoading = true;
        const { data } = await services.produto.getMaisAcessados({
          type: state.currentClientType,
        });
        console.log(data);
        state.destaques = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function submitContato() {
      try {
        state.isLoading = true;
        const { data } = await services.produto.getMaisAcessados({
          type: state.currentClientType,
        });
        console.log(data);
        state.destaques = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function enviaForm() {
      const { data } = await services.contato.contato({
        nome: state.nome,
        email: state.email,
        telefone: state.telefone,
        assunto: state.assunto,
        mensagem: state.mensagem,
      });
      (state.nome = ""),
        (state.email = ""),
        (state.telefone = ""),
        (state.assunto = ""),
        (state.mensagem = ""),
        console.log(data);
      alert("E-mail Enviado!");
    }
    function mostrarDiv1(mostrar) {
      state.div_orcamento = mostrar;
    }
    function mostrarDiv2(mostrar) {
      state.div_orcamento2 = mostrar;
    }
    function mostrarDiv3(mostrar) {
      state.div_orcamento3 = mostrar;
    }
    return {
      Topo,
      Bottom,
      state,
      mostrarDiv1,
      mostrarDiv2,
      mostrarDiv3,
      submitContato,
      enviaForm,
    };
  },
};
</script>
<style>
@import "../../app.scss";
</style>
<style scoped>
body {
  background: #161616;
}
body * {
  font-family: "Montserrat";
}
.div_orcamento {
  transition: height 300ms cubic-bezier(0.3, 0, 0.6, 1);
}
textarea:focus,
input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
  border: #01bd9e solid 2px;
}
#sct_home {
  width: 100%;
  background-color: #000;
}

#home {
  background-color: #000 !important;

  background-size: 100%;
  height: 483px;
  background-position: center;
  background-repeat: no-repeat;
}

#home img {
  display: none;
}

/*---Section id="banner_2---*/
#nosso_servicos {
  background-color: #161616;
  padding-top: 120px;
  padding-bottom: 40px;
}
.div_title {
  text-align: center;
  color: #fff;
  line-height: 1;
}
.title {
  color: #fff;
  line-height: 1;
  font-size: 28pt;
  font-family: "PoppinsBold";
}
.txt_azul {
  color: #01bd9e;
  font-family: "PoppinsBold";
}
.descricao {
  color: #fff;
  text-align: center;
  font-family: "PoppinsRegular";
}
.div_nss_servico {
  margin-top: 90px;
}
.nss_servico {
  color: #fff;
  text-align: center;
  font-size: 44px;
  font-weight: bold;
}
.nss_span {
  font-weight: 100;
  font-size: 44px;
  font-family: "PoppinsExtraBold";
}
.coluna {
  text-align: center;
  padding: 20px;
  border: 2px solid #01bd9e;
  border-radius: 25px;
  position: relative;
}
.coluna_title {
  height: 90px;
}
.coluna_title h1 {
  line-height: 1.3;
  color: #fff;
  text-align: center;
  font-family: "PoppinsMedium";
}
.coluna_descricao {
  color: #fff;
  line-height: 1;
  font-family: "PoppinsRegular";
  height: 40px;
}
.orcamento {
  background: none;
  border: none;
  color: #01bd9e;
  font-weight: 900;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "PoppinsRegular";
  margin-bottom: 0;
  padding-bottom: 0;
}
.svg_orcamento {
  color: #01bd9e;
}

/*---Section id="banner_3---*/
#portfolio {
  background-color: #1e1e1e;
}
.div_title2 {
  text-align: center;
  margin-top: 100px;
}
.title_portfolio {
  color: #fff;
  text-align: center;
  font-size: 40pt;
  font-family: "PoppinsBold";
}
.divisor {
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #01bd9e -6.09%, #002c39 99.63%);
  border-radius: 45px;
}
.coluna_port {
  text-align: center;
  padding: 20px;
  background: rgba(221, 221, 221, 0.1);
  border-radius: 12px;
  margin-top: 40px;
  margin-bottom: 70px;
  color: #fff;
}
.h1_sifit {
  color: #fff;
  font-family: "PoppinsMedium";
}
.descricao_port {
  font-family: "PoppinsRegular";
  color: #fff;
  height: 50px;
}
.btn_portfolio {
  background-color: transparent;
  border: #01bd9e solid 1px;
  border-radius: 30px;
  color: #01bd9e;
  font-weight: 700;
  padding: 10px;
  transition: 0.5s;
  width: 40%;
  font-family: "PoppinsRegular";
}
.btn_portfolio:hover {
  background-color: #01bd9e;
  color: #fff;
}

/*---Section id="banner_4"---*/
#contato {
  background-image: url("../../assets/img/background_2.png");
  height: 5/00px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 15px;
}
.conteudo {
  padding: 25px;
  margin-top: 30px;
}
.title_ctt {
  color: #fff;
  font-size: 50px;
  font-family: "PoppinsBold";
}
.ctt {
  color: #01bd9e;
  font-family: "PoppinsBold";
}
.descricao_ctt {
  color: #fff;
  font-size: 20px;
  font-family: "PoppinsMedium";
}
svg {
  color: #01bd9e;
}
.p_svg {
  color: #fff;
  font-family: "PoppinsRegular";
}
.linha {
  width: 100%;
  height: 0px;
  background: #757575;
  border: 1px solid #757575;
}
.social {
  color: #fff;
  width: 30px;
  height: 50px;
  margin-right: 10px;
  margin-top: 10px;
  transition: 1s;
}
.social:hover {
  color: #01bd9e;
}
form {
  text-align: center;
  padding: 30px;
  background: rgba(221, 221, 221, 0.4);
  border-radius: 12px;
  color: #fff;
  margin-top: 50px;
  text-align: left;
  margin-bottom: 50px;
}
form div + div {
  margin-top: 0px;
}
label {
  width: 100px;
  color: white;
  text-align: left;
  font-family: "PoppinsMedium";
}
input,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: #252525 solid 2px;
  border-radius: 10px;
  background: #252525;
  color: white;
  resize: none;
  margin-top: 10px;
  transition: 0.5s;
  font-family: "PoppinsRegular";
}
input:hover,
textarea:hover {
  border: #01bd9e solid 2px;
}
textarea {
  vertical-align: top;
  height: 5em;
  color: white;
  font-family: "PoppinsRegular";
}
.btn_send {
  padding: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  text-align: center;
  border: none;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(93.31deg, #01bd9e 0.68%, #002c39 109.79%);
  border-radius: 40px;
  width: 50%;
  font-size: 11pt;
  margin-top: 10px;
  font-family: "PoppinsMedium";
}
.btn_send:hover {
  background: linear-gradient(93.31deg, #002c39 0.68%, #01bd9e 109.79%);
  color: #fff;
}
.zap {
  z-index: 100;
  position: fixed;
  right: 30px;
  width: 50px;
  transition: 0.5s;
}
.zap:hover {
  color: #fff;
}
.zap_space {
}

@media (min-width: 1445px) {
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
}

@media (min-width: 1200px) and (max-width: 1444px) {
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
  .title {
    font-size: 23pt;
  }
  .coluna_descricao {
    line-height: 1;
  }
  .img_port {
    width: 100%;
  }
  .btn_send {
    font-size: 10pt;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #home {
    height: auto;
    background: none;
    padding-top: 40px;
  }
  #home img {
    width: 100%;
    display: block;
  }
  .title {
    font-size: 20pt;
  }
  .nss_servico {
    line-height: 1;
  }
  .coluna {
    height: 260px;
  }
  .coluna_title h1 {
    font-size: 15pt;
  }
  .coluna_descricao {
    line-height: 1;
    font-size: 12pt;
    height: 100px;
  }
  .orcamento {
    font-size: 8pt;
  }
  .img_port {
    width: 100%;
  }
  .btn_portfolio {
    font-size: 12px;
    width: 100%;
  }
  #contato {
    background-position: top;
    background-size: cover;
  }
  .title_ctt {
    font-size: 40px;
  }
  .descricao_ctt {
    font-size: 15px;
  }
  form {
    margin-top: 30px;
  }
  .descricao_port {
    height: 100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .title {
    font-size: 25pt;
  }
  .nss_servico {
    font-size: 40px;
  }
  .coluna_title {
    height: 30px;
  }
  .coluna {
    height: auto;
    margin-bottom: 10px;
  }
  .coluna_title h1 {
    font-size: 10pt;
  }
  .coluna_descricao {
    line-height: 1.2;
    font-size: 10pt;
  }
  .orcamento {
    font-weight: 700;
    font-size: 8pt;
    text-align: center;
  }
  .title_portfolio {
    font-size: 35pt;
  }
  .btn_portfolio {
    width: 30%;
  }
  .title_ctt {
    font-size: 40px;
  }
  .descricao_ctt {
    font-size: 12pt;
  }
  form {
    margin-top: 30px;
  }
  .btn_send {
    width: 40%;
  }
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
}
@media (max-width: 575px) {
  .title {
    font-size: 17pt;
    padding-top: 50px;
  }
  .descricao {
    font-size: 10pt;
  }
  .div_nss_servico {
    margin-top: 50px;
  }
  .nss_servico {
    font-size: 35px;
  }
  .coluna {
    margin-bottom: 15px;
  }
  .div_title2 {
    margin-top: 60px;
  }
  .img_port {
    width: 100%;
  }
  .title_ctt {
    font-size: 33px;
  }
  .descricao_ctt {
    font-size: 15px;
  }
  form {
    margin-top: 20px;
    padding: 20px;
  }
  .btn_send {
    padding: 7px;
    font-size: 9pt;
  }
  #home {
    height: auto;
    background: none;
    padding-top: 100px;
  }
  #home img {
    width: 100%;
    display: block;
  }
  .btn_portfolio {
    width: 100%;
  }
  #nosso_servicos {
    margin-top: 0;
  }
  #nosso_servicos .title {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>