<template>
  <topo />

  <section id="home">
    <img src="../../assets/img/background_1.png" />
  </section>
  <div class="zap_space">
    <a
      href="https://api.whatsapp.com/send?phone=55044984387269&text=Olá, gostaria de conhecer seus serviços!"
      target="blank"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-whatsapp zap"
        viewBox="0 0 16 16"
      >
        <path
          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
        />
      </svg>
    </a>
  </div>

  <section id="nosso_servicos">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3 div_title" data-aos="fade-down">
          <h1 class="title">
            TRANSFORME SUA <span class="txt_azul"> IDEIA </span> EM UMA
            <span class="txt_azul"> SOLUÇÃO LUCRATIVA </span>
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <p class="descricao" data-aos="fade-down">
            Conte com as melhores tecnologias e profissionais experientes para
            executar o seu projeto. Construímos aplicativos, sistemas web e
            muitas outras soluções digitais.
          </p>
        </div>
      </div>
      <div class="row" data-aos="zoom-in-up">
        <div class="col-md-6 offset-md-3 div_nss_servico">
          <h1 class="nss_servico">
            Nossos <span class="nss_span"> Serviços </span>
          </h1>
        </div>
      </div>
      <div class="row" data-aos="zoom-in-up">
        <div class="col-md-4">
          <div
            class="coluna"
            @mouseover="mostrarDiv1(true)"
            @mouseleave="mostrarDiv1(false)"
          >
            <div>
              <div class="coluna_title">
                <h1>Desenvolvimento de Aplicativos</h1>
              </div>
              <div class="coluna_descricao">
                Criação de Aplicativos Web e Mobile
              </div>
            </div>
            <Collapse :when="state.div_orcamento" class="div_orcamento">
              <a href="#contato">
                <button class="orcamento">
                  Solicite um orçamento
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-short svg_orcamento"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </button>
              </a>
            </Collapse>
          </div>
        </div>

        <div class="col-md-4">
          <div
            class="coluna"
            @mouseover="mostrarDiv2(true)"
            @mouseleave="mostrarDiv2(false)"
          >
            <div class="coluna_title">
              <h1>Desenvolvimento de Sistemas Web</h1>
            </div>
            <div class="coluna_descricao">
              Aplicações WEB completas com as melhores tecnologias do mercado
            </div>
            <Collapse :when="state.div_orcamento2" class="div_orcamento">
              <a href="#contato"
                ><button class="orcamento">
                  Solicite um orçamento
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-short svg_orcamento"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </button>
              </a>
            </Collapse>
          </div>
        </div>

        <div class="col-md-4">
          <div
            class="coluna"
            @mouseover="mostrarDiv3(true)"
            @mouseleave="mostrarDiv3(false)"
          >
            <div class="coluna_title">
              <h1>Web Design</h1>
            </div>
            <div class="coluna_descricao">
              Criação de Sites, Landing Pages e Lojas Virtuais
            </div>
            <Collapse :when="state.div_orcamento3" class="div_orcamento">
              <a href="#contato">
                <button class="orcamento">
                  Solicite um orçamento
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </button>
              </a>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="divisor"></div>
  <section id="portfolio">
    <div class="container">
      <div class="row">
        <div div class="col-md-6 offset-md-3 div_title2" data-aos="zoom-in">
          <h1 class="title_portfolio">Portfólio</h1>
        </div>
      </div>

      <div class="row">
        <div div class="col-md-4">
          <div class="coluna_port" data-aos="fade-up">
            <img
              src="@/assets/img/port_sifit.png"
              alt="sifit"
              class="img_port"
            />
            <h1 class="h1_sifit">SIFIT</h1>
            <div class="descricao_port">Sistema de Gestão de Academias</div>
            <a href="http://www.sifit.com.br" target="_blank">
              <button type="button" class="btn_portfolio">Ver online</button>
            </a>
          </div>
        </div>
        <div div class="col-md-4">
          <div class="coluna_port" data-aos="fade-up">
            <img
              src="@/assets/img/port_centrion_previna.png"
              alt="sifit"
              class="img_port"
            />
            <h1 class="h1_sifit">PREVINA</h1>
            <div class="descricao_port">Previna Manutenções Prediais</div>
            <a href="http://www.previnamanutencoes.com.br" target="_blank">
              <button type="button" class="btn_portfolio">Ver online</button>
            </a>
          </div>
        </div>
        <div div class="col-md-4">
          <div class="coluna_port" data-aos="fade-up">
            <img
              src="@/assets/img/port_centrion_linuxti.png"
              alt="sifit"
              class="img_port"
            />
            <h1 class="h1_sifit">TI Linux</h1>
            <div class="descricao_port">
              Servidores e serviços de informática
            </div>
            <a href="http://www.tilinux.com.br" target="_blank">
              <button type="button" class="btn_portfolio">Ver online</button>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div div class="col-md-4">
          <div class="coluna_port" data-aos="fade-up">
            <img
              src="@/assets/img/port_centrion_calcados.png"
              alt="sifit"
              class="img_port"
            />
            <h1 class="h1_sifit">Calçados Pantanal</h1>
            <div class="descricao_port">
              Fábrica de Calçados em Umuarma - PR
            </div>
            <a href="http://www.calcadospantanal.com.br" target="_blank">
              <button type="button" class="btn_portfolio">Ver online</button>
            </a>
          </div>
        </div>
        <div div class="col-md-4">
          <div class="coluna_port" data-aos="fade-up">
            <img
              src="@/assets/img/port_centrion_smart.png"
              alt="sifit"
              class="img_port"
            />
            <h1 class="h1_sifit">Smartsellers</h1>
            <div class="descricao_port">Empresa situada em Umuarma - PR</div>
            <a href="http://www.smartsellers.com.br" target="_blank">
              <button type="button" class="btn_portfolio">Ver online</button>
            </a>
          </div>
        </div>
        <div div class="col-md-4">
          <div class="coluna_port" data-aos="fade-up">
            <img
              src="@/assets/img/port_centrion_conteles.png"
              alt="sifit"
              class="img_port"
            />
            <h1 class="h1_sifit">Conteles</h1>
            <div class="descricao_port">Empresa situada em Maringá - PR</div>
            <a href="http://www.conteles.com.br" target="_blank">
              <button type="button" class="btn_portfolio">Ver online</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="divisor"></div>
  <section id="contato">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="conteudo" data-aos="fade-right">
            <h1 class="title_ctt">Entre em <span class="ctt">Contato</span></h1>
            <p class="descricao_ctt">
              Nossa equipe pode te ajudar a tirar sua ideia do papel.
              Trabalhamos com diversas tecnologias para melhor te atender.
            </p>
            <p class="p_svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
                />
              </svg>
              atendimento@centrion.com.br
            </p>
            <p class="p_svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-whatsapp"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                />
              </svg>
              +55 44 98438-7269
            </p>
            <p class="p_svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
                />
                <path
                  d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
              Av Rio Grande Do Norte, 3454 Umuarama, Paraná
            </p>
            <div class="linha"></div>
            <a target="_blank" href="https://www.instagram.com/centrionweb/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-instagram social"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                />
              </svg>
            </a>
            <a target="_blank" href="https://pt-br.facebook.com/centrionweb/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-facebook social"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                />
              </svg>
            </a>
            <a
              target="blank"
              href="https://br.linkedin.com/company/centrionweb?trk=public_profile_experience-item_profile-section-card_image-click&original_referer=https%3A%2F%2Fwww.google.com%2F"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin social"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                />
              </svg>
            </a>
          </div>
        </div>

        <div class="col-lg-5">
          <form action="" data-aos="fade-left">
            <input type="text" placeholder="Seu nome" v-model="state.nome" />
            <input
              type="email"
              placeholder="Seu melhor e-mail"
              v-model="state.email"
            />
            <input
              type="telefone"
              placeholder="Telefone/WhatsApp"
              v-model="state.telefone"
            />
            <textarea
              placeholder="O que tem em mente? Ex: Um aplicativo"
              v-model="state.assunto"
            ></textarea>
            <textarea
              placeholder="Digite sua mensagem"
              v-model="state.mensagem"
            ></textarea>
            <button type="button" class="btn_send" @click="enviaForm">
              Envie sua mensagem
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
  <bottom />
</template>
<script>
import Topo from "../../layout/Site/Topo.vue";
import Bottom from "../../layout/Site/Bottom.vue";
import { onMounted, reactive } from "vue";
import services from "../../services";

export default {
  setup() {
    onMounted(() => {
      if (window.$wow) {
        window.$wow.init();
        window.$wow.sync();
      }
      fetchBanners();
      fetchDestaques();
    });

    const state = reactive({
      nome: "",
      email: "",
      telefone: "",
      titulo: "",
      mensagem: "",
      destaques: [],
    });

    async function fetchBanners() {
      try {
        state.isLoading = true;
        const { data } = await services.banner.getAtivos({
          type: state.currentClientType,
        });
        state.banners = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function fetchDestaques() {
      try {
        state.isLoading = true;
        const { data } = await services.produto.getMaisAcessados({
          type: state.currentClientType,
        });
        console.log(data);
        state.destaques = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    async function submitContato() {
      try {
        state.isLoading = true;
        const { data } = await services.produto.getMaisAcessados({
          type: state.currentClientType,
        });
        console.log(data);
        state.destaques = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }
    async function enviaForm() {
      const { data } = await services.contato.contato({
        nome: state.nome,
        email: state.email,
        telefone: state.telefone,
        assunto: state.assunto,
        mensagem: state.mensagem,
      });
      (state.nome = ""),
        (state.email = ""),
        (state.telefone = ""),
        (state.assunto = ""),
        (state.mensagem = ""),
        console.log(data);
      alert("E-mail Enviado!");
    }
    function mostrarDiv1(mostrar) {
      state.div_orcamento = mostrar;
    }
    function mostrarDiv2(mostrar) {
      state.div_orcamento2 = mostrar;
    }
    function mostrarDiv3(mostrar) {
      state.div_orcamento3 = mostrar;
    }
    return {
      Topo,
      Bottom,
      state,
      mostrarDiv1,
      mostrarDiv2,
      mostrarDiv3,
      submitContato,
      enviaForm,
    };
  },
};
</script>
<style>
@import "../../app.scss";
</style>
<style scoped>
body {
  background: #161616;
}
body * {
  font-family: "Montserrat";
}
.div_orcamento {
  transition: height 300ms cubic-bezier(0.3, 0, 0.6, 1);
}
textarea:focus,
input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
  border: #01bd9e solid 2px;
}
#sct_home {
  width: 100%;
  background-color: #000;
}

#home {
  background-color: #000 !important;

  background-size: 100%;
  height: 483px;
  background-position: center;
  background-repeat: no-repeat;
}

#home img {
  display: none;
}

/*---Section id="banner_2---*/
#nosso_servicos {
  background-color: #161616;
  padding-top: 120px;
  padding-bottom: 40px;
}
.div_title {
  text-align: center;
  color: #fff;
  line-height: 1;
}
.title {
  color: #fff;
  line-height: 1;
  font-size: 28pt;
  font-family: "PoppinsBold";
}
.txt_azul {
  color: #01bd9e;
  font-family: "PoppinsBold";
}
.descricao {
  color: #fff;
  text-align: center;
  font-family: "PoppinsRegular";
}
.div_nss_servico {
  margin-top: 90px;
}
.nss_servico {
  color: #fff;
  text-align: center;
  font-size: 44px;
  font-weight: bold;
}
.nss_span {
  font-weight: 100;
  font-size: 44px;
  font-family: "PoppinsExtraBold";
}
.coluna {
  text-align: center;
  padding: 20px;
  border: 2px solid #01bd9e;
  border-radius: 25px;
  position: relative;
}
.coluna_title {
  height: 90px;
}
.coluna_title h1 {
  line-height: 1.3;
  color: #fff;
  text-align: center;
  font-family: "PoppinsMedium";
}
.coluna_descricao {
  color: #fff;
  line-height: 1;
  font-family: "PoppinsRegular";
  height: 40px;
}
.orcamento {
  background: none;
  border: none;
  color: #01bd9e;
  font-weight: 900;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "PoppinsRegular";
  margin-bottom: 0;
  padding-bottom: 0;
}
.svg_orcamento {
  color: #01bd9e;
}

/*---Section id="banner_3---*/
#portfolio {
  background-color: #1e1e1e;
}
.div_title2 {
  text-align: center;
  margin-top: 100px;
}
.title_portfolio {
  color: #fff;
  text-align: center;
  font-size: 40pt;
  font-family: "PoppinsBold";
}
.divisor {
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #01bd9e -6.09%, #002c39 99.63%);
  border-radius: 45px;
}
.coluna_port {
  text-align: center;
  padding: 20px;
  background: rgba(221, 221, 221, 0.1);
  border-radius: 12px;
  margin-top: 40px;
  margin-bottom: 70px;
  color: #fff;
}
.h1_sifit {
  color: #fff;
  font-family: "PoppinsMedium";
}
.descricao_port {
  font-family: "PoppinsRegular";
  color: #fff;
  height: 50px;
}
.btn_portfolio {
  background-color: transparent;
  border: #01bd9e solid 1px;
  border-radius: 30px;
  color: #01bd9e;
  font-weight: 700;
  padding: 10px;
  transition: 0.5s;
  width: 40%;
  font-family: "PoppinsRegular";
}
.btn_portfolio:hover {
  background-color: #01bd9e;
  color: #fff;
}

/*---Section id="banner_4"---*/
#contato {
  background-image: url("../../assets/img/background_2.png");
  height: 5/00px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 15px;
}
.conteudo {
  padding: 25px;
  margin-top: 30px;
}
.title_ctt {
  color: #fff;
  font-size: 50px;
  font-family: "PoppinsBold";
}
.ctt {
  color: #01bd9e;
  font-family: "PoppinsBold";
}
.descricao_ctt {
  color: #fff;
  font-size: 20px;
  font-family: "PoppinsMedium";
}
svg {
  color: #01bd9e;
}
.p_svg {
  color: #fff;
  font-family: "PoppinsRegular";
}
.linha {
  width: 100%;
  height: 0px;
  background: #757575;
  border: 1px solid #757575;
}
.social {
  color: #fff;
  width: 30px;
  height: 50px;
  margin-right: 10px;
  margin-top: 10px;
  transition: 1s;
}
.social:hover {
  color: #01bd9e;
}
form {
  text-align: center;
  padding: 30px;
  background: rgba(221, 221, 221, 0.4);
  border-radius: 12px;
  color: #fff;
  margin-top: 50px;
  text-align: left;
  margin-bottom: 50px;
}
form div + div {
  margin-top: 0px;
}
label {
  width: 100px;
  color: white;
  text-align: left;
  font-family: "PoppinsMedium";
}
input,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: #252525 solid 2px;
  border-radius: 10px;
  background: #252525;
  color: white;
  resize: none;
  margin-top: 10px;
  transition: 0.5s;
  font-family: "PoppinsRegular";
}
input:hover,
textarea:hover {
  border: #01bd9e solid 2px;
}
textarea {
  vertical-align: top;
  height: 5em;
  color: white;
  font-family: "PoppinsRegular";
}
.btn_send {
  padding: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  text-align: center;
  border: none;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(93.31deg, #01bd9e 0.68%, #002c39 109.79%);
  border-radius: 40px;
  width: 50%;
  font-size: 11pt;
  margin-top: 10px;
  font-family: "PoppinsMedium";
}
.btn_send:hover {
  background: linear-gradient(93.31deg, #002c39 0.68%, #01bd9e 109.79%);
  color: #fff;
}
.zap {
  z-index: 100;
  position: fixed;
  right: 30px;
  width: 50px;
  transition: 0.5s;
}
.zap:hover {
  color: #fff;
}
.zap_space {
}

@media (min-width: 1445px) {
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
}

@media (min-width: 1200px) and (max-width: 1444px) {
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
  .title {
    font-size: 23pt;
  }
  .coluna_descricao {
    line-height: 1;
  }
  .img_port {
    width: 100%;
  }
  .btn_send {
    font-size: 10pt;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #home {
    height: auto;
    background: none;
    padding-top: 40px;
  }
  #home img {
    width: 100%;
    display: block;
  }
  .title {
    font-size: 20pt;
  }
  .nss_servico {
    line-height: 1;
  }
  .coluna {
    height: 260px;
  }
  .coluna_title h1 {
    font-size: 15pt;
  }
  .coluna_descricao {
    line-height: 1;
    font-size: 12pt;
    height: 100px;
  }
  .orcamento {
    font-size: 8pt;
  }
  .img_port {
    width: 100%;
  }
  .btn_portfolio {
    font-size: 12px;
    width: 100%;
  }
  #contato {
    background-position: top;
    background-size: cover;
  }
  .title_ctt {
    font-size: 40px;
  }
  .descricao_ctt {
    font-size: 15px;
  }
  form {
    margin-top: 30px;
  }
  .descricao_port {
    height: 100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .title {
    font-size: 25pt;
  }
  .nss_servico {
    font-size: 40px;
  }
  .coluna_title {
    height: 30px;
  }
  .coluna {
    height: auto;
    margin-bottom: 10px;
  }
  .coluna_title h1 {
    font-size: 10pt;
  }
  .coluna_descricao {
    line-height: 1.2;
    font-size: 10pt;
  }
  .orcamento {
    font-weight: 700;
    font-size: 8pt;
    text-align: center;
  }
  .title_portfolio {
    font-size: 35pt;
  }
  .btn_portfolio {
    width: 30%;
  }
  .title_ctt {
    font-size: 40px;
  }
  .descricao_ctt {
    font-size: 12pt;
  }
  form {
    margin-top: 30px;
  }
  .btn_send {
    width: 40%;
  }
  #home {
    height: auto;
    background: none;
  }
  #home img {
    width: 100%;
    display: block;
  }
}
@media (max-width: 575px) {
  .title {
    font-size: 17pt;
    padding-top: 50px;
  }
  .descricao {
    font-size: 10pt;
  }
  .div_nss_servico {
    margin-top: 50px;
  }
  .nss_servico {
    font-size: 35px;
  }
  .coluna {
    margin-bottom: 15px;
  }
  .div_title2 {
    margin-top: 60px;
  }
  .img_port {
    width: 100%;
  }
  .title_ctt {
    font-size: 33px;
  }
  .descricao_ctt {
    font-size: 15px;
  }
  form {
    margin-top: 20px;
    padding: 20px;
  }
  .btn_send {
    padding: 7px;
    font-size: 9pt;
  }
  #home {
    height: auto;
    background: none;
    padding-top: 100px;
  }
  #home img {
    width: 100%;
    display: block;
  }
  .btn_portfolio {
    width: 100%;
  }
  #nosso_servicos {
    margin-top: 0;
  }
  #nosso_servicos .title {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>