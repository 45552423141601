var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9iYWNrZW5kYWdyby5jZW50cmlvbi5jb20uYnJcL2FwaVwvbG9naW4iLCJpYXQiOjE2NTQ2OTgyMDMsImV4cCI6MTY1NDcwMTgwMywibmJmIjoxNjU0Njk4MjAzLCJqdGkiOiJ3MzhPRTNhVWFlQmI2SXRhIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.gVCti-_bkN9c8k8Lo1WQQEcqc0gx6MfMfc8frJchgkg';


export default httpClient => ({
  getAll: async () => {

    const response = await httpClient.get('/usuarios',{
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }

  },
  getById: async ({ id }) => {
    const response = await httpClient.get('/usuario/'+id,{ 
      headers: {
        'X-Header-Token': token
      }
    })
    return { data: response.data }
  },
            
  save: async ({ nome, email, senha, status }) => {
    const response = await httpClient.post('/usuario/adicionar',{
      nome,
      email,
      senha,
      status
    },{
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ id, nome, email, senha, status }) => {
    const response = await httpClient.post('/usuario/alterar/'+id,{
      nome,
      email,
      senha,
      status
    },{
      headers: {
        'X-Header-Token': token
      }
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
    delete: async ({ id }) => {
    const response = await httpClient.delete('/usuario/deletar/' + id, { 
      headers: {
        'X-Header-Token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9iYWNrZW5kYWdyby5jZW50cmlvbi5jb20uYnJcL2FwaVwvbG9naW4iLCJpYXQiOjE2NTQ2OTgyMDMsImV4cCI6MTY1NDcwMTgwMywibmJmIjoxNjU0Njk4MjAzLCJqdGkiOiJ3MzhPRTNhVWFlQmI2SXRhIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.gVCti-_bkN9c8k8Lo1WQQEcqc0gx6MfMfc8frJchgkg'
      }
    })
    return { data: response.data }
  }
})